// FIXME: re-examine organization of variables, files, ordering etc.  While the ordering works, we need to be sure this is more comprehensible.
@import "~bootstrap-material-design/scss/variables/colors";
@import "~bootstrap-material-design/scss/variables/shadow";

// redefine ? TODO: do we need this bmd variant?  This is used as $body-color
$gray-lighter: rgba($black, 0.12) !default;
$gray-light: rgba($black, 0.26) !default;
$gray-alpha: .54 !default;
$gray: rgba($black, $gray-alpha) !default; // spec color
$gray-dark: rgba($black, 0.87) !default; // used for text color - others use grey-600 which is considerably lighter

$bmd-font-weight-base: 400;

// wondering if any of these could still be refactored out, but are definitely in use.
$bmd-inverse: rgba($white, 1) !default;
$bmd-inverse-light: rgba($white, 0.84) !default;
$bmd-inverse-lighter: rgba($white, 0.54) !default;

$bmd-label-color: $gray-light !default;
$bmd-label-color-inner-focus: $gray !default; // e.g. radio label or text-muted not a control-label which is primary

// Bootstrap Material Design default colors (these can be override by user)
$primary:       $teal !default;
$success:       $green !default;
$info:          $light-blue !default;
$warning:       $deep-orange !default;
$danger:        $red !default;
$light:         $grey-100 !default;
$dark:          $grey-800 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);

// Customized BS variables
@import "~bootstrap-material-design/scss/variables/bootstrap/components";
@import "~bootstrap-material-design/scss/variables/bootstrap/custom-forms";
@import "~bootstrap-material-design/scss/variables/bootstrap/spacing";
@import "~bootstrap-material-design/scss/variables/bootstrap/body";
@import "~bootstrap-material-design/scss/variables/bootstrap/buttons";
@import "~bootstrap-material-design/scss/variables/bootstrap/card";
@import "~bootstrap-material-design/scss/variables/bootstrap/code";
@import "~bootstrap-material-design/scss/variables/bootstrap/dropdown";
@import "~bootstrap-material-design/scss/variables/bootstrap/forms";
@import "~bootstrap-material-design/scss/variables/bootstrap/list-group";
@import "~bootstrap-material-design/scss/variables/bootstrap/nav";
@import "~bootstrap-material-design/scss/variables/bootstrap/pagination";
@import "~bootstrap-material-design/scss/variables/bootstrap/state";
@import "~bootstrap-material-design/scss/variables/bootstrap/tables";
@import "~bootstrap-material-design/scss/variables/bootstrap/tooltip";
@import "~bootstrap-material-design/scss/variables/bootstrap/type";
@import "~bootstrap-material-design/scss/variables/bootstrap/modals";

// import their vars after customization for use below
$enable-flex: true; // fully adopt flexbox layouts
$enable-shadows: true; // enable shadows, set to false to turn off shadows
@import "~bootstrap/scss/functions"; // from bootstrap node_module
@import "~bootstrap/scss/variables"; // from bootstrap node_module

//
@import "~bootstrap-material-design/scss/variables/layout";
@import "~bootstrap-material-design/scss/variables/menu";
@import "~bootstrap-material-design/scss/variables/drawer";
@import "~bootstrap-material-design/scss/variables/snackbar";

$bmd-label-color-focus: theme-color(primary) !default;
$bmd-invalid-underline: $red-a700 !default;
$bmd-readonly-underline: $input-border-color !default;

//---
// verified in use with refactoring to v4

//---
//-- unverified below here
$bmd-brand-inverse: $indigo !default;
// Typography elements FIXME: review to see if we actually need these
$icon-color: rgba($black, 0.5) !default;

//---
// FIXME: Similar but not quite the same as Bootstrap variables
// FIXME: these need to either a) be converted to $bmd- or b) converted to bs variables
//---

// --------------------
// inputs
$bmd-bmd-label-static-size-ratio: 75 / 100 !default;
$bmd-help-size-ratio: 75 / 100 !default;

//$bmd-form-control-bg-repeat-y: repeat-y !default; // it could work with no-repeat, but on Safari it's bugged and repeat-y is needed, but repeat-y is bugged on the warning icon.
$bmd-form-control-bg-repeat-y: no-repeat !default;
$bmd-form-control-bg-position: center bottom, center calc(100% - 1px) !default;
$bmd-form-control-bg-size: 0 100%, 100% 100% !default;
$bmd-form-control-bg-size-active: 100% 100%, 100% 100% !default;

// expandable
$input-text-button-size: 32px !default;

// sizing
$bmd-form-line-height: 1 !default; // set as 1x font-size so that padding is easier calculated to match the spec.
$bmd-label-top-margin-base: 1rem !default;

$bmd-form-line-height-lg: 1 !default; // set as 1x font-size so that padding is easier calculated to match the spec.
$bmd-label-top-margin-lg: 1rem !default; // 16px

$bmd-form-line-height-sm: 1 !default; // set as 1x font-size so that padding is easier calculated to match the spec.
$bmd-label-top-margin-sm: .75rem !default; // 12px

$text-disabled: #a8a8a8 !default;
$background-disabled: #eaeaea !default;

// Checkboxes
$bmd-checkbox-size: 1.25rem !default;
$bmd-checkbox-animation-ripple: 500ms !default;
$bmd-checkbox-animation-check: 0.3s !default;
$bmd-checkbox-checked-color: theme-color(primary) !default;
$bmd-checkbox-label-padding: .3125rem !default; // 5px

$bmd-checkbox-border-size: .125rem !default;
$bmd-checkbox-border-color: $bmd-label-color-inner-focus !default;
$bmd-checkbox-border-color-disabled: $gray-light !default; //#bdbdbd !default;

// Switches
$bmd-switch-label-padding: .3125rem !default; // 5px
$bmd-switch-width: 2.125rem !default; // 34px
$bmd-switch-height: .875rem !default; // 14px
$bmd-switch-handle-size: 1.25rem !default; // 20px (was 18px)

$bmd-switch-handle-checked-bg: theme-color(primary) !default;
$bmd-switch-handle-unchecked-bg: #f1f1f1 !default;
$bmd-switch-handle-disabled-bg: #bdbdbd !default;
$bmd-switch-unchecked-bg: $gray-light !default;
$bmd-switch-checked-bg: desaturate(
  lighten($bmd-switch-handle-checked-bg, 28%),
  32%
); // kind of magic recipe
$bmd-switch-disabled-bg: $gray-lighter !default;

// Popovers and Popups
$bmd-popover-background: rgba(101, 101, 101, 0.9) !default;
$bmd-popover-color: #ececec !default;

// Radio:
$bmd-radio-border: .125rem !default; // 2px
$bmd-radio-size: 1.25rem !default;
$bmd-radio-ripple-offset: 1em !default;
$bmd-radio-label-padding: .3125rem !default; // 5px

$bmd-radio-color-off: $bmd-label-color-inner-focus !default; // FIXME seems inconsistent, check spec
$bmd-radio-color-on: theme-color(primary) !default;
$bmd-radio-color-disabled: $gray-light; // light theme spec: Disabled: #000000, Opacity  26%
$bmd-radio-color-disabled-inverse: rgba(
  $white,
  0.30
); // dark theme spec: Disabled: #FFFFFF, Opacity  30%

// Animations
$bmd-animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1) !default;
$bmd-animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1) !default;
$bmd-animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1) !default;
$bmd-animation-curve-default: $bmd-animation-curve-fast-out-slow-in !default;
