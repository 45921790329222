/*! Theme created with the Bootstrap Shuffle (https://bootstrapshuffle.com) */

// Your variables
@import 'custom-variables';
@import 'bootstrap-material-design-variables';

@import "~bootstrap-material-design/scss/_mixins";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-material-design/scss/reboot";

@import "~bootstrap-material-design/scss/layout";
@import "~bootstrap-material-design/scss/buttons";
@import "~bootstrap-material-design/scss/checkboxes";
@import "~bootstrap-material-design/scss/custom-forms";
@import "~bootstrap-material-design/scss/switches";
@import "~bootstrap-material-design/scss/radios";
@import "~bootstrap-material-design/scss/forms";
@import "~bootstrap-material-design/scss/input-group";
@import "~bootstrap-material-design/scss/list-group";
@import "~bootstrap-material-design/scss/tables";
@import "~bootstrap-material-design/scss/nav";
@import "~bootstrap-material-design/scss/navbar";
@import "~bootstrap-material-design/scss/alerts";
@import "~bootstrap-material-design/scss/progress";
@import "~bootstrap-material-design/scss/pagination";
@import "~bootstrap-material-design/scss/type";
@import "~bootstrap-material-design/scss/tooltip";
@import "~bootstrap-material-design/scss/popover";
@import "~bootstrap-material-design/scss/cards";
@import "~bootstrap-material-design/scss/modal";
@import "~bootstrap-material-design/scss/dropdown";
@import "~bootstrap-material-design/scss/drawer";
@import "~bootstrap-material-design/scss/themes";
@import "~bootstrap-material-design/scss/ripples";
@import "~bootstrap-material-design/scss/_extensions";